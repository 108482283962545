import * as React from "react"
import Layout from "../layout"
import Seo from "../seo"
import TheContainer from "../components/TheContainer"
import TheHeader from "../components/TheHeader"
import { StaticImage } from "gatsby-plugin-image"
// @ts-ignore
import TruckIcon from '../icons/truck.svg'
// @ts-ignore
import UsersIcon from '../icons/users.svg'

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div
      className='mt-12'
    >
      <TheHeader />
    </div>
    <TheContainer>
      <div
        className='py-16 sm:py-32 flex flex-col sm:flex-row flex-col-reverse items-center justify-start'
      >
        <div
          className={'mt-4 text-center sm:text-left sm:mt-0 sm:mr-10 sm:w-1/2 lg:1/7'}
        >
          <h3
            className={'text-3xl sm:text-4xl font-bold'}
          >
            <b>
              Transport &<br/>
              Spedycja
            </b>
          </h3>
          <p
            className={'mt-6 text-gray-400 text-xl'}
          >
            Nasza firma posiada 25 własnych samochodów.
            Jako firma nie tylko transportowa, ale też spedycyjna, organizujemy transport na terenie całej europy i kraju.
            Współpracujemy z przewoźnikami sprawdzonymi i sumiennymi.
          </p>
        </div>
        <StaticImage
          src='../images/photo-1.jpg'
          alt='Trucks aerial view'
          width={800}
          quality={95}
        />
      </div>
      <div
        className='pb-32 flex flex-col sm:flex-row items-center justify-start'
      >
        <StaticImage
          src='../images/photo-2.jpg'
          alt='Trucks aerial view'
          width={800}
          quality={95}
        />
        <div
          className={'mt-4 text-center sm:text-left sm:mt-0 sm:ml-10 sm:w-1/2 lg:3/7'}
        >
          <h3
            className={'text-3xl sm:text-4xl font-bold'}
          >
            <b>
              Oferta
            </b>
          </h3>
          <p
            className={'mt-6 text-gray-400 text-xl'}
          >
            Mamy przyjemność zaproponować Państwu współpracę w zakresie transportu towarów na skalę krajową i międzynarodową.
            Nasza oferta skierowana jest do firm handlowych i produkcyjnych zajmujących się eksportem i importem towarów.
          </p>
        </div>
      </div>
    </TheContainer>
    <div
      id='o-nas'
      className='border-t border-gray-300 py-32'
    >
      <TheContainer
        className='text-center'
      >
        <div
          className='flex justify-center flex-row mb-5'
        >
          <UsersIcon
            height={50}
            width={50}
            stroke={'#111111'}
          />
        </div>
        <b
          className='text-3xl sm:text-4xl font-bold'
        >
          O nas
        </b>
        <p
          className='mt-8 text-gray-400 text-xl'
        >
          Nasza firma konsekwentnie realizuje politykę jakości która gwarantuje wszystkim Klientom wysoki poziom obsługi,
          zarówno na etapie kontraktowania jak również w trakcie realizacji procesu przewozowego. W trosce o naszych Zleceniodawców
          wprowadzamy innowacyjne rozwiązania logistyczne i stale unowocześniamy flotę pojazdów. Gwarantujemy Państwu terminowość
          odbioru i dostaw ładunków, zapewniamy możliwie najkrótszy czas realizacji zleceń oraz korzystne ceny. Zatrudniamy doświadczonych
          kierowców, a wysokie wyposażenie techniczne i tabor samochodowy w nienagannym stanie powodują, że współpraca z naszą firmą jest
          źródłem satysfakcji.
        </p>
      </TheContainer>
    </div>
    <div
      style={{
        backgroundColor: '#ff5a00'
      }}
      className='py-32 text-white'
    >
      <TheContainer
        className='text-center'
      >
        <div
          className='flex justify-center flex-row mb-5'
        >
          <TruckIcon
            height={50}
            width={50}
            stroke={'#ffffff'}
          />
        </div>
        <b
          className='text-3xl sm:text-4xl font-bold'
        >
          Dostarczymy każdy ładunek na czas!
        </b>
        <p
          className='mt-8 text-gray-100 text-xl'
        >
          Dysponujemy kilkudziesięcioma nowoczesnymi pojazdami o ładowności do <b>24 ton</b> i naczepami <b>13,6</b>.
          Wykonujemy regularne kursy do Rumunii, Bułgarii, Słowacji, Grecji oraz Węgier.
          W ostatnim czasie poszerzyliśmy swoją ofertę o kierunki skandynawskie (m. in. Finlandia, Szwecja)
          W trosce o bezpieczeństwo naszych klientów, zarówno krajowych jak i międzynarodowych, gwarantujemy ubezpieczenie
          na przewożone towary opiewające na kwotę <b>900 000,00 PLN</b>.
        </p>
      </TheContainer>
    </div>
    <div
      id='kontakt'
      className='py-32'
    >
      <TheContainer
        className='sm:flex items-start justify-center'
      >
        <div
          className='md:w-4/7 bg-gray-50 w-full overflow-hidden'
        >
          <iframe
            width='100%'
            height="500"
            loading="lazy"
            allowFullScreen
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2561.936668154317!2d19.97915835203978!3d50.05001767932122!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471644ca8d7a0ea7%3A0xd7e72e7f62aff775!2sNEOVIS%20SP.%20Z%20O.O.!5e0!3m2!1sen!2spl!4v1629320965018!5m2!1sen!2spl"
          >

          </iframe>
        </div>
        <div
          className='w-full md:w-3/7 text-center sm:text-left mt-5 sm:ml-10'
        >
          <b
            className='text-3xl sm:text-4xl'
          >
            Zadzwoń już teraz!
          </b>
          <div
            className='text-gray-500'
          >
            <p
              className='text-lg mt-5'
            >
              +48 12 31 56 384
            </p>
            <p
              className='text-lg'
            >
              transport@neovis.com.pl
            </p>
            <StaticImage
              width={200}
              src={'../images/logo.png'}
              className='mb-3 mt-8'
              alt={'Logo Neovis'}
            /><br />
            <b>
              Neovis sp. z o.o
            </b>
            <p>ul. Stoczniowców 3, 30-709, Kraków</p><br/>
            <p>KRS: 0000652176</p>
            <p>NIP: 6793138540</p>
            <p>REGON: 366082170</p>
          </div>
        </div>
      </TheContainer>
    </div>
  </Layout>
)

export default IndexPage
