import * as React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image"

import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'
import TheContainer from "./TheContainer"

const TheHeader = () => {
  const { placeholderImage, seamlessBackground } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "main-background.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
       seamlessBackground: file(
          relativePath: { eq: "main-background.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 95, maxWidth: 420) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const image = getImage(placeholderImage)
  const imageData = seamlessBackground.childImageSharp.fluid

  // Use like this:
  const bgImage = convertToBgImage(image)

  return (
    <BackgroundImage
      Tag="section"
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      fluid={imageData}
      preserveStackingContext
      backgroundColor='#111'
    >
      <div
        className='py-32 md:py-44 lg:py-60 flex items-center justify-center text-center text-white'
        style={{
          backgroundColor: 'rgba(0, 0, 0, .25)'
        }}
      >
        <TheContainer>
          <div
            className='px-16'
          >
            <StaticImage
              src="../images/logo.png"
              loading='eager'
              width={350}
              fit='contain'
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              objectFit='contain'
              alt="Neovis logo"
            />
          </div>
          <p
            className='mt-4 md:mt-8 lg:mt-12 text-xl sm:text-3xl lg:text-5xl font-semibold'
          >
            Transport krajowy i zagraniczny
          </p>
        </TheContainer>
      </div>
    </BackgroundImage>
  )}

export default TheHeader
